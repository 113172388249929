"use client"

import toast from "react-hot-toast";
import { IBlogSession } from '@/lib/authOptions';
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { BASE_URL } from "@/config/config";



const NewPostContext = createContext<{
  title: string;
  handleTitleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  publish: () => void,
  postIsEmpty: boolean,
  error: any,
  loading: boolean,
  publisherIsActive: boolean,
  togglePublisherIsActive: () => void,
  scheduledTime: string | Date | null,
  coverImage: string;
  setScheduledTime: Dispatch<SetStateAction<null>>,
  scheduleArticle: boolean,
  setScheduleArticle: Dispatch<SetStateAction<boolean>>,
  toggleScheduleArticle: (e: MouseEvent<HTMLButtonElement>) => void,
  handleTagsChange: (e: ChangeEvent<HTMLInputElement>) => void,
  tags: string,
  description: string,
  handleDescriptionChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
  keywords: string,
  handleKeywordsChange: (e: ChangeEvent<HTMLInputElement>)=> void
} | null>(null);

interface IPost {
  title: string;
  content: string;
  author: string;
  coverImage: string;
  tags: string[];
  scheduledTime: string | Date | null;
  description: string;

  keywords: string;
}

function NewPostProvider({ children }: { children: React.ReactNode }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [publisherIsActive, setPublisherIsActive] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [scheduleArticle, setScheduleArticle] = useState(false);
  const [coverImage, setCoverImage] = useState("");
  const [tags, setTags] = useState("");
  const [randomCoverImage, setRandomCoverImage] = useState(true);
  const [description, setDescription] = useState("")
  const [keywords, setKeywords] = useState("")
  const postIsEmpty = !title || !content;

  const { data } = useSession();
  const session: IBlogSession | null = data as IBlogSession | null
  const router = useRouter();

  useEffect(
    function () {
      if (!randomCoverImage) return;
      setCoverImage(
        `https://picsum.photos/seed/${title
          .split(" ")
          .join("")
          .replaceAll("/", "")}/400/200`
      );
    },
    [title, randomCoverImage]
  );

  function handleTitleChange(e: ChangeEvent<HTMLInputElement>) {
    setTitle(e.target.value);
  }

  function handleTagsChange(e: ChangeEvent<HTMLInputElement>) {
    setTags(e.target.value);
  }

  function togglePublisherIsActive() {
    setPublisherIsActive((publisherIsActive) => !publisherIsActive);
  }

  function toggleScheduleArticle() {
    setScheduleArticle((scheduleArticle) => !scheduleArticle);
  }

  function handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(e.target.value)
  }
  function handleKeywordsChange(e: ChangeEvent<HTMLInputElement>) {
    setKeywords(e.target.value)
  }

  async function publish() {
    const newPost: IPost = {
      title,
      content,
      description,
      author: session?.user?.id as string,
      coverImage,
      tags: tags.trim().split(" "),
      scheduledTime: null,
      keywords,
    };

    if (scheduleArticle) newPost.scheduledTime = scheduledTime;

    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/posts`, {
        method: "POST",
        body: JSON.stringify(newPost),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data.status !== "success") throw new Error(data.message);

      toast.success("You post was published successfully");

      setTitle("");
      setContent("");
      router.replace(`/blog/${data.article?.slug}`);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message)
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <NewPostContext.Provider
      value={{
        title,
        handleTitleChange,
        content,
        setContent,
        publish,
        postIsEmpty,
        error,
        loading,
        publisherIsActive,
        togglePublisherIsActive,
        scheduledTime,
        setScheduledTime,
        scheduleArticle,
        setScheduleArticle,
        toggleScheduleArticle,
        handleTagsChange,
        tags,
        coverImage,
        description,
        handleDescriptionChange,
        keywords,
        handleKeywordsChange
      }}
    >
      {children}
    </NewPostContext.Provider>
  );
}

function useNewPost() {
  const context = useContext(NewPostContext);
  if (!context)
    throw new Error("NewPostContext was used outside of NewPostProvider");
  return context;
}

export { useNewPost, NewPostProvider };
