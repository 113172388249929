"use client";

import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

export interface IImage {
  id: number | string;
  url: string;
  fileName: string;
  size: {
    name: string;
    size: number;
  };
}

const StateContext = createContext<{
  data: IImage[];
  setData: Dispatch<SetStateAction<IImage[]>>;
}>({
  data: [],
  setData: () => {},
});

export function StateProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = useState<IImage[]>([]);

  return (
    <StateContext.Provider value={{ data, setData }}>
      {children}
    </StateContext.Provider>
  );
}

export function useStateValue() {
  return useContext(StateContext);
}
